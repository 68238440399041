<template>
  <v-container>
    <form v-on:submit.prevent="save">
      <validation-observer ref="observer" v-slot="{ validate, reset }">
        <v-card max-width="800px" class="mx-auto">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>{{$t('withdrawal')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <validation-provider name="amount" rules="required|min_value:1" v-slot="{ errors }">
                    <v-text-field
                      :error-messages="errors"
                      :label="$t('amount')"
                      name="amount"
                      type="number"
                      v-model="editedItem.amount"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider name="withdraw_key" rules="required" v-slot="{ errors }">
                    <v-text-field
                      :error-messages="errors"
                      :label="$t('withdraw_key')"
                      name="withdraw_key"
                      type="password"
                      v-model="editedItem.withdraw_key"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider name="from_bankflag" rules="required" v-slot="{ errors }">
                    <v-combobox
                      :error-messages="errors"
                      :label="$t('to_bankflag')"
                      name="to_bankflag"
                      :items="Banks"
                      item-text="name"
                      item-value="name"
                      :return-object="false"
                      v-model="editedItem.to_bankflag"
                    >
                    </v-combobox>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider name="to_cardnumber" rules="required" v-slot="{ errors }">
                    <v-text-field
                      :error-messages="errors"
                      :label="$t('to_cardnumber')"
                      name="to_cardnumber"
                      type="text"
                      v-model="editedItem.to_cardnumber"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider name="to_cardname" rules="required" v-slot="{ errors }">
                    <v-text-field
                      :error-messages="errors"
                      :label="$t('to_cardname')"
                      name="to_cardname"
                      type="text"
                      v-model="editedItem.to_cardname"
                    />
                  </validation-provider>
                </v-col>
                <template v-if="$app_country === 'CN'">
                  <v-col cols="6">
                    <validation-provider name="to_branch" rules="required" v-slot="{ errors }">
                      <v-text-field
                        :error-messages="errors"
                        :label="$t('to_branch')"
                        name="to_branch"
                        type="text"
                        v-model="editedItem.to_branch"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider name="to_province" rules="required" v-slot="{ errors }">
                      <v-combobox
                        :error-messages="errors"
                        :label="$t('to_province')"
                        name="to_province"
                        :items="province"
                        item-text="name"
                        item-value="name"
                        :return-object="false"
                        v-model="editedItem.to_province"
                      ></v-combobox>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider name="to_city" rules="required" v-slot="{ errors }">
                      <v-combobox
                        :error-messages="errors"
                        :label="$t('to_city')"
                        name="to_city"
                        :items="city"
                        item-text="name"
                        item-value="name"
                        :return-object="false"
                        v-model="editedItem.to_city"
                      ></v-combobox>
                    </validation-provider>
                  </v-col>
                </template>
                <v-col cols="6">
                  <v-combobox
                    :label="$t('currency')"
                    :items="CurrencyList"
                    item-text="currency_code"
                    item-value="currency_code"
                    :return-object="false"
                    v-model="editedItem.currency"
                  >
                  </v-combobox>
                </v-col>
                <validation-provider name="withdrawal_currency" rules="required" v-slot="{ errors }">
                  <v-radio-group
                    v-model="editedItem.payment_currency"
                    :label="$t('withdrawal_currency')"
                    :error-messages="errors"
                    row
                  >
                    <v-radio
                      v-for="currency in paymentCurrencies"
                      :key="currency"
                      :label="currency"
                      :value="currency"
                    ></v-radio>
                  </v-radio-group>
                </validation-provider>
                <v-col cols="12">
                  <v-alert
                    border="left"
                    colored-border
                    type="warning"
                    elevation="2"
                  >
                    {{$t('client_withdrawal_note')}}
                  </v-alert>
                  <validation-provider name="tnc" rules="agree" v-slot="{ errors }">
                    <v-checkbox
                      :error-messages="errors"
                      v-model="agreement"
                      :label="$t('i_agree_tnc')"
                    ></v-checkbox>
                  </validation-provider>
                </v-col>
<!--                <v-col cols="12" sm="12">-->
<!--                  <ValidationProvider name="remark" v-slot="{ errors }">-->
<!--                    <v-textarea-->
<!--                      :error-messages="errors"-->
<!--                      :label="$t('remark')"-->
<!--                      v-model="editedItem.remark"-->
<!--                      name="remark"-->
<!--                      type="text"-->
<!--                      auto-grow-->
<!--                      rows="3"-->
<!--                    ></v-textarea>-->
<!--                  </ValidationProvider>-->
<!--                </v-col>-->
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="busy" @click="save" color="primary">{{$t('save')}}</v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </form>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import { required, min_value, max_value } from 'vee-validate/dist/rules';
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import chinaMixins from '../../../mixins/chinaMixins';
  import i18n from '../../../plugins/i18n'
  import y3Mixins from "../../../mixins/y3Mixins";
  import currencyMixins from "../../../mixins/currencyMixins";

  extend('required', required);

  extend('min_value', min_value);
  extend('max_value', max_value);

  extend('agree', {
    validate(value) {
      return {
        required: true,
        valid: value === true,
      };
    },
    message() {
      return i18n.t('you_must_agree_to_tnc');
    },
    computesRequired: true,
  });

  export default {
    name: 'ClientWithdraw',
    mixins: [y3Mixins, chinaMixins, currencyMixins],
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    computed: {
      ...mapGetters({
        user: 'auth/user',
      }),
      paymentCurrencies () {
        let sortedCurrencies = ['USDT', 'CNY', 'SGD', 'PHP', 'HKD', 'MYR', 'VND', 'IDR', 'THB', 'USD', 'INR'];
        sortedCurrencies.sort(function(x,y){
          return x === Vue.prototype.$app_currency_code ? -1 : y === Vue.prototype.$app_currency_code ? 1 : 0;
        });
        return sortedCurrencies;
      }
    },
    mounted () {
      this._fetchCurrency();
    },
    data: function() {
      return {
        agreement: null,
        bankList: [],
        dialog: false,
        busy: false,
        search: '',
        editedItem: {
          currency: null,
          amount: '0',
          to_bankflag: '',
          to_cardnumber: '',
          to_cardname: '',
          to_province: '',
          to_city: '',
          to_branch: '',
          remark: '',
          withdraw_key: '',
          payment_currency: '',
          // notifyurl: 'http://localhost:8083/callback'
        },
        defaultItem: {
          currency: null,
          amount: '0',
          to_bankflag: '',
          to_cardnumber: '',
          to_cardname: '',
          to_province: '-',
          to_city: '-',
          to_branch: '',
          remark: '',
          withdraw_key: '',
          payment_currency: '',
          // notifyurl: 'http://localhost:8083/callback'
        },
      }
    },
    methods: {
      _fetchCurrency () {
        this.busy = true;
        let _this = this;
        this
          .fetchCurrency()
          .finally(function () {
            _this.busy = false;
          })
      },
      save() {
        this.addTransaction();
      },
      addTransaction() {
        const _this = this;

        this.$refs.observer.validate().then(result => {
          if (result) {
            _this.busy = true;

            axios.post(`/app/api/client/${_this.user.user_id}/withdraw`, _this.editedItem)
              .then(function () {
                _this.editedItem = Object.assign({}, _this.defaultItem);
                _this.$router.push({name: 'client.customerTransaction', params: { status: 'pending', type: 'withdrawal' }})
              })
              .catch(function (error) {
                // Show error.
                _this.$store.dispatch('snackbar/openSnackbar', {
                  message: error.response.data.message,
                  color: 'error',
                })
              })
              .finally(function () {
                _this.busy = false;
              })
          }
        })
      },
    },
  }
</script>
