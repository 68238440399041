<template>
  <v-container>
    <v-card>
      <v-toolbar
        color="primary"
        dark
        flat
      >
        <v-toolbar-title>{{$t('transaction_record')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <!--        <v-btn icon :to="{name: 'contact.insert'}">-->
        <!--          <v-icon>mdi-plus-circle</v-icon>-->
        <!--        </v-btn>-->

        <v-btn icon @click="downloadTrx">
          <v-icon>mdi-download</v-icon>
<!--          <v-icon>mdi-dots-vertical</v-icon>-->
        </v-btn>

        <template v-slot:extension>
          <v-tabs
            align-with-title
            background-color="transparent"
          >
            <v-tabs-slider color="white"></v-tabs-slider>

            <v-tab v-for="(item, index) in items" :to="item.route" :key="index">
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <div>
        <v-card-title>
          {{$t('transaction')}}
          <v-spacer></v-spacer>
          <v-row>
            <v-col>
              <v-datetime-picker
                v-model="fromDate"
                :text-field-props="{prependIcon: 'mdi-calendar'}"
                :label="$t('date_from')"
                :ok-text="$t('confirm')"
                :clear-text="$t('clear')"
                @input="resetSummaryAndFetch()"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col>
              <v-datetime-picker
                v-model="toDate"
                :text-field-props="{prependIcon: 'mdi-calendar'}"
                :label="$t('date_to')"
                :ok-text="$t('confirm')"
                :clear-text="$t('clear')"
                @input="resetSummaryAndFetch()"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col v-if="$route.params.type !== 'adjust'">
              <v-select
                :disabled="trxFilterOptions.length === 0"
                item-text="text"
                item-value="code"
                v-model="trxFilter"
                :items="trxFilterOptions"
                :label="$t('Filter')"
                clearable
              ></v-select>
            </v-col>
            <v-col>
              <v-combobox
                :label="$t('currency')"
                :items="CurrencyList"
                item-text="currency_code"
                item-value="currency_code"
                :return-object="false"
                v-model="currencyFilter"
                clearable
                @change="resetSummaryAndFetch()"
              >
              </v-combobox>
            </v-col>
            <v-col>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                @click:append="resetSummaryAndFetch()"
                @keydown.enter="resetSummaryAndFetch()"
                :label="$t('search')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card 
          v-if="isCompleted && isTopupOrWithdrawal && trxSummary" 
          class="d-flex justify-end" 
          flat 
          tile
        >
          <v-card 
            v-for="(summary, idx) in trxSummary" 
            :key="idx" 
            class="mx-1"
            rounded
            outlined
          >
            <v-card-text class="ma-0 pa-1">
              <v-chip>{{ summary.currency }}</v-chip> 
              <v-chip>
                {{ $t('total_amount_short') + `: ${summary.amt}`}} 
              </v-chip>
              <v-chip>
                {{ $t('total_commission_short') + `: ${summary.commission}`}} 
              </v-chip>
            </v-card-text>
          </v-card>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="transactionList"
          :page="page"
          :pageCount="numberOfPages"
          :options.sync="options"
          :multi-sort="true"
          :must-sort="false"
          :footer-props="{
            'show-current-page': true,
            'items-per-page-options': rowsPerPageItem,
            'show-first-last-page': true,
          }"
          :server-items-length="totalTransactions"
          :loading="loading"
        >
          <template v-slot:footer.prepend>
            <ValidationObserver ref="pageInputObserver" v-slot="{ validate, reset }">
              <ValidationProvider name="page" :rules="{integer: true, min_value: 1, max_value: numberOfPages}" v-slot="{ errors }">
                <v-text-field
                  v-model.number="pageInput"
                  type="number"
                  append-outer-icon="mdi-chevron-right-circle-outline"
                  @click:append-outer="jumpToPage"
                  :label="$t('Go to page')"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </ValidationObserver>
          </template>
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="500px">
<!--                <template v-slot:activator="{ on }">-->
<!--                  <v-btn color="primary" dark class="mb-2" v-on="on">{{$t('new_transaction')}}</v-btn>-->
<!--                </template>-->
              <transaction-form
                :form-title="formTitle"
                :edited-item="editedItem" :edited-index="editedIndex"
                @fetch="fetch" @close="close"
              ></transaction-form>
            </v-dialog>
          </template>
          <template v-slot:item.bank="{ item }">
<!--            {{ item.bank.bank }} {{item.bank.province}}{{item.bank.city}}{{item.bank.branch}}-->
            {{ item.bank.bank }}
          </template>
          <template v-slot:item.amount="{ item }">
            {{ item.amount ? item.amount.toLocaleString(undefined, {minimumFractionDigits: 2}) : '-'}}
          </template>
          <template v-slot:item.commission="{ item }">
            {{ item.commission ? item.commission.toLocaleString(undefined, {minimumFractionDigits: 2}) : '-' }}
          </template>
          <template v-slot:item.net_amount="{ item }">
            {{ item.net_amount ? item.net_amount.toLocaleString(undefined, {minimumFractionDigits: 2}) : '-' }}
          </template>
          <template v-slot:item.creation_time="{ item }">
            {{ new Date(item.creation_time).toLocaleString() }}
          </template>
          <template v-slot:item.paytype="{ item }">
            {{ $t(item.paytype) }}
          </template>
          <template v-slot:item.transaction_type="{ item }">
            {{ $t(getKeyByValue(TransactionType, item.transaction_type)) }}
          </template>
          <template v-slot:item.transaction_status="{ item }">
            <span :class="getStatusColor(item.transaction_status, item.assignee)">
              {{ getStatusType(item.transaction_status, item.assignee) }}
            </span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="viewItem(item.transaction_number)"
            >
              mdi-file-find
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="resetSummaryAndFetch()">
              {{$t('refresh')}}
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import transactionMixins from '../../../mixins/transactionMixins'
  import currencyMixins from '../../../mixins/currencyMixins'
  import { integer, min_value, max_value } from 'vee-validate/dist/rules';
  import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
  import FileDownload from 'js-file-download';
  import TransactionForm from "./TransactionForm";

  extend('integer', integer);
  extend('min_value', min_value);
  extend('max_value', max_value);

  export default {
    name: 'ClientWithdraw',
    mixins: [transactionMixins, currencyMixins],
    components: {
      TransactionForm,
      ValidationProvider,
      ValidationObserver,
    },

    mounted() {
      // this.fetch();

      this.fetchCurrency();
    },
    computed: {
      isCompleted () {
        return this.$route.params.status === 'complete'
      },
      isTopupOrWithdrawal() {
        return (
          this.$route.params.type === 'topup' || 
          this.$route.params.type === 'withdrawal'
        );
      },
      items () {
        if (this.$route.params.type === 'adjust') {
          return [
            {
              name: this.$t('completed'),
              route: {name: 'task', params: { status: 'complete', type: this.$route.params.type}}
            }
          ];
        } else {
          return [
            {
              name: this.$t('completed'),
              route: {name: this.$route.name, params: {status: 'complete', type: this.$route.params.type}}
            },
            {
              name: this.$t('pending'),
              route: {name: this.$route.name, params: {status: 'pending', type: this.$route.params.type}}
            },
          ];
        }
      },
      ...mapGetters({
        downloadLocale: 'lang/locale',
        user: 'auth/user',
      }),
      headers () {
        if (this.$route.params.type === 'adjust') {
          return [
            {text: this.$t('creation_time'), align: 'left', value: 'creation_time',},
            { text: this.$t('transaction_number'), value: 'transaction_number'},
            { text: this.$t('adj_txn'), value: 'adj_txn'},
            { text: this.$t('adj_client_order_id'), value: 'adj_client_order_id'},
            { text: this.$t('transaction_type'), value: 'transaction_type', sortable: false },
            { text: this.$t('currency'), value: 'currency_code', sortable: false },
            { text: this.$t('amount'), value: 'amount', sortable: false },
            { text: this.$t('commission'), value: 'commission', sortable: false },
            { text: this.$t('transaction_status'), value: 'transaction_status', sortable: false },
            { text: this.$t('remark'), value: 'remark.user', sortable: false },
          ];
        }
        let arr = [
          {
            text: this.$t('creation_time'),
            align: 'left',
            sortable: true,
            value: 'creation_time',
          },
          { text: this.$t('transaction_number'), value: 'transaction_number'},
          { text: this.$t('client_order_id'), value: 'client_order_id'},
          { text: this.$t('client_user_id'), value: 'client_user_id'},
          { text: this.$t('paytype'), value: 'paytype', sortable: false },
          { text: this.$t('transaction_type'), value: 'transaction_type', sortable: false },
          { text: this.$t('currency'), value: 'currency_code', sortable: false },
          { text: this.$t('amount'), value: 'amount', sortable: false },
          { text: this.$t('commission'), value: 'commission', sortable: false },
          { text: this.$t('net_amount'), value: 'net_amount', sortable: false },
          { text: this.$t('transaction_status'), value: 'transaction_status', sortable: false },
          // { text: this.$t('actions'), value: 'action', sortable: false },
        ];
        if (this.$route.params.type === 'withdrawal') {
          arr.splice(4, 0, ...[
            {text: this.$t('to_bankflag'), value: 'bank', sortable: false},
            {text: this.$t('to_cardnumber'), value: 'beneficiary_account', sortable: false},
            {text: this.$t('to_cardname'), value: 'beneficiary_name', sortable: false},
          ]);
          arr.splice(-4, 0, ...[
            { text: this.$t('withdrawal_currency'), value: 'payment_currency', sortable: false },
          ]);
        } else { //topup
          arr.splice(4, 0, ...[
            {text: this.$t('from_cardname'), value: 'beneficiary_name', sortable: false},
          ]);
        }
        return arr;
      },
      formTitle () {
        return this.editedIndex === -1 ? this.$t('new_transaction') : this.$t('transaction_details')
      },
      trxFilterOptions() {
        if (this.$route.params.status === 'complete') {
          return [
            {code: 'completed', text: this.$t('completed_trx')},
            {code: 'rejected', text: this.$t('rejected_trx')},
          ];
        } else {
          return [];
        }
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      '$route.params.type': function () {
        this.resetOptions();
      },
      '$route.params.status': function () {
        this.resetOptions();
      },
      trxFilter () {
        this.resetOptions();
      },
      options: {
        handler() {
          this.fetch();
        },
      },
      deep: true,
    },
    data: function() {
      return {
        pageInput: '',
        menu1: false,
        menu2: false,
        page: 1,
        totalTransactions: 0,
        numberOfPages: 0,
        options: {
          page: 1,
          itemsPerPage: 50,
          sortBy: ['creation_time'],
          sortDesc: [true],
          multiSort: true,
          mustSort: false,
        },
        loading: false,
        rowsPerPageItem: [50, 100, 200],
        dialog: false,
        busy: false,
        fromDate: null,
        toDate: null,
        search: '',
        trxFilter: '',
        currencyFilter: '',
        transactionList: [],
        editedIndex: -1,
        editedItem: {
          transaction_number: null,
          paytype: null,
          amount: 0,
          commission: 0,
          net_amount: 0,
          currency_code: this.$app_currency_code,
          transaction_type: null,
          client_id: null,
          cs_agent_id: null,
          transaction_status: null,
          creation_time: null,
          completion_time: null,
          remark: {
            client: null,
          },
          bank: {
            bank_code: null,
            country_code: null,
            location_code: null,
            branch_code: null,
          },
          beneficiary_account: null,
          beneficiary_name: null,
          assignee: null,
        },
        defaultItem: {
          transaction_number: null,
          paytype: null,
          amount: 0,
          commission: 0,
          net_amount: 0,
          currency_code: this.$app_currency_code,
          transaction_type: null,
          client_id: null,
          cs_agent_id: null,
          transaction_status: null,
          creation_time: null,
          completion_time: null,
          remark: {
            client: null,
          },
          bank: {
            bank_code: null,
            country_code: null,
            location_code: null,
            branch_code: null,
          },
          beneficiary_account: null,
          beneficiary_name: null,
          assignee: null,
        },
        trxSummary: null,
      }
    },
    methods: {
      async downloadTrx () {
        this.fetch(true);
      },
      resetSummaryAndFetch() {
        this.trxSummary = null;
        this.fetch();
      },
      async fetch (download = false) {
        const _this = this;

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let pageNumber = page;

        let endpoint = ''
        if (this.$route.params.status === 'complete') {
          endpoint = '/app/api/transaction/completed'
        } else if (this.$route.params.status === 'pending') {
          endpoint = '/app/api/transaction/pending'
        } else {
          return
        }

        let params = {
          params: {
            page: pageNumber,
            count: itemsPerPage,
            tz: new Date().getTimezoneOffset(),
            sortBy: sortBy,
            sortDesc: sortDesc,
          }
        };
        if (this.fromDate) {
          const fromDate = Date.parse(this.fromDate);

          if (!isNaN(fromDate)) {
            params.params.fromDate = fromDate;
          }
        }
        if (this.toDate) {
          const toDate = Date.parse(this.toDate);

          if (!isNaN(toDate)) {
            params.params.toDate = toDate;
          }
        }
        if (this.search) {
          params.params.search = this.search;
        }
        let trxType = null;
        if (this.$route.params.type === 'topup') {
          trxType = [this.TransactionType.TOP_UP, this.TransactionType.COLLECT];
        } else if (this.$route.params.type === 'withdrawal') {
          trxType = [this.TransactionType.WITHDRAW, this.TransactionType.PAY];
        } else if (this.$route.params.type === 'adjust') {
          trxType = [this.TransactionType.ADJUSTMENT];
        }
        if (trxType !== null) {
          params.params.type = trxType;
        }
        if (this.trxFilter === 'completed') {
          params.params.status = this.TransactionStatus.COMPLETED;
        } else if (this.trxFilter === 'rejected') {
          params.params.status = this.TransactionStatus.REJECTED;
        }

        if (this.currencyFilter) {
          params.params.currency = this.currencyFilter;
        }
        
        const needUpdateTrxSummary = (
          this.isCompleted && 
          this.isTopupOrWithdrawal && 
          this.trxSummary === null
        );

        if (needUpdateTrxSummary) {
          params.params.summary = true;
        }

        if (download) {
          params.params.download = true;
          params.params.locale = this.downloadLocale;
          params.responseType = 'blob';
          await axios.get(endpoint, params)
            .then((response) => {
              FileDownload(response.data, 'report.xlsx');
            })
            .catch(async function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: JSON.parse(await error.response.data.text()).message,
                color: 'error',
              })
            });
        } else {
          this.busy = true;
          this.loading = true;
          await axios.get(endpoint, params)
            .then(function (response) {
              _this.transactionList = response.data.data.data;
              _this.totalTransactions = response.data.data.total;
              _this.numberOfPages = response.data.data.total_page;

              let trxSummary = response.data.data.summary;

              if (needUpdateTrxSummary && trxSummary) {
                _this.trxSummary = Object
                  .keys(trxSummary)
                  .map(x => ({
                    currency: x, 
                    amt: trxSummary[x].amount,
                    commission: trxSummary[x].commission,
                  }));
              }
            })
            .catch(function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: error.response.data.message,
                color: 'error',
              })
            })
            .finally(function () {
              _this.loading = false;
              _this.busy = false;
            })
        }
      },
      async viewItem (id) {
        this.editedIndex = 0;
        this.busy = true;
        const _this = this;
        await axios.get(`/app/api/transaction/${id}`)
          .then(function (response) {
            _this.editedItem = Object.assign({}, response.data.data);
            _this.dialog = true
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      close () {
        this.dialog = false;
        // setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        // }, 300)
      },
      resetOptions () {
        this.page = 1;
        this.totalTransactions = 0;
        this.numberOfPages = 0;
        this.options = {
          page: 1,
          itemsPerPage: 50,
          sortBy: ['creation_time'],
          sortDesc: [true],
          multiSort: true,
          mustSort: false,
        };
        // this.trxFilter = '';
        // this.search = '';

        this.trxSummary = null;
      },
      jumpToPage () {
        this.$refs.pageInputObserver.validate().then(result => {
          if (result && this.pageInput) {
            this.page = this.pageInput;
            this.pageInput = '';
          }
        });
      },
    },
  }
</script>
