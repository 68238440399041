import * as user from '../constants/system_user'

let contactMixins = {
  data () {
    return {
      UserStatus: user.UserStatus,
      UserType: user.UserType
    }
  },
  methods: {
    getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
  }
}

export default contactMixins;
