<template>
  <v-container>
    <v-card>
      <v-toolbar
        color="primary"
        dark
        flat
      >
        <v-toolbar-title>{{$t('bank')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <!--        <v-btn icon :to="{name: 'contact.insert'}">-->
        <!--          <v-icon>mdi-plus-circle</v-icon>-->
        <!--        </v-btn>-->

        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs
            align-with-title
            background-color="transparent"
          >
            <v-tabs-slider color="white"></v-tabs-slider>

<!--            <v-tab v-for="(item, index) in items" :to="item.route" :key="index">-->
<!--              {{ item.name }}-->
<!--            </v-tab>-->
          </v-tabs>
        </template>
      </v-toolbar>

      <div>
        <v-card-title>
          {{$t('all')}}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="bankList"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{$t('bank')}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark class="mb-2" v-on="on">{{$t('new_bank')}}</v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      @click="close"
                    >
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            :label="$t('bank_code')"
                            v-model="editedItem.bank_code"
                            name="bank_code"
                            type="text"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            :label="$t('country_code')"
                            v-model="editedItem.country_code"
                            name="country_code"
                            type="text"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            :label="$t('location_code')"
                            v-model="editedItem.location_code"
                            name="location_code"
                            type="text"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            :label="$t('branch_code')"
                            v-model="editedItem.branch_code"
                            name="branch_code"
                            type="text"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            :label="$t('bank_name')"
                            v-model="editedItem.bank_name"
                            name="bank_name"
                            type="text"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-checkbox
                            v-model="editedItem.bank_enabled"
                            :label="$t('bank_enabled')"
                            name="bank_card_enabled"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-combobox
                            :label="$t('currency')"
                            :items="CurrencyList"
                            item-text="currency_code"
                            item-value="currency_code"
                            :return-object="false"
                            v-model="editedItem.currency_code"
                          >
                          </v-combobox>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            :label="$t('max_amt_per_trx')"
                            v-model.number="editedItem.max_amt_per_trx"
                            name="max_amt_per_trx"
                            type="number"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            :label="$t('bank_banner_colour')"
                            v-model="editedItem.bank_banner_colour"
                          >
                            <template v-slot:append>
                              <v-menu v-model="colorPickerMenu"  @input="updateColor" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                <template v-slot:activator="{ on }">
                                  <div :style="swatchStyle" v-on="on" />
                                </template>
                                <v-card>
                                  <v-card-text class="pa-0">
                                    <v-color-picker
                                      v-model="colorPicker"
                                      mode="hexa"
                                      hide-mode-switch
                                      flat
                                    />
                                  </v-card-text>
                                </v-card>
                              </v-menu>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            :label="$t('bank_url')"
                            v-model="editedItem.bank_url"
                            type="url"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            :label="$t('supported_paytype')"
                            :items="supported_paytype"
                            v-model="editedItem.paytype"
                            multiple
                            chips
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-file-input
                            id="fileUpload"
                            :label="$t('bank_banner_logo')"
                            accept="image/jpeg,image/png"
                            v-model="bank_banner_img"
                            @change="convertToBase64"
                            @click:clear="clearBankBannerLogo"
                          >
                          </v-file-input>
                        </v-col>
                        <v-col cols="12" sm="6" class="my-auto">
                          <img :src="displayBankBanner" width="100%">
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{$t('cancel')}}</v-btn>
                    <v-btn color="blue darken-1" text @click="save" :loading="busy">{{$t('save')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="fetch">{{$t('refresh')}}</v-btn>
          </template>
        </v-data-table>
      </div>

    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { PayType } from "../../constants/y3.js";
  import currencyMixins from '../../mixins/currencyMixins'

  export default {
    name: 'AllContact',
    mixins: [currencyMixins],
    mounted() {
      this.fetch();
      this.fetchCurrency();
    },
    computed: {
      headers () {
        return [
          {
            text: this.$t('bank_id'),
            align: 'left',
            sortable: true,
            value: 'bank_id',
          },
          { text: this.$t('bank_code'), value: 'bank_code' },
          { text: this.$t('country_code'), value: 'country_code' },
          { text: this.$t('location_code'), value: 'location_code' },
          { text: this.$t('branch_code'), value: 'branch_code' },
          { text: this.$t('bank_name'), value: 'bank_name' },
          { text: this.$t('bank_enabled'), value: 'bank_enabled' },
          { text: this.$t('actions'), value: 'action', sortable: false },
        ]
      },
      formTitle () {
        return this.editedIndex === -1 ? this.$t('new_bank') : this.$t('edit_bank')
      },
      swatchStyle() {
        return {
          backgroundColor: this.editedItem.bank_banner_colour ?
            this.editedItem.bank_banner_colour : this.$vuetify.theme.currentTheme.primary,
          cursor: 'pointer',
          height: '30px',
          width: '30px',
          borderRadius: this.colorPickerMenu ? '50%' : '4px',
          transition: 'border-radius 200ms ease-in-out'
        }
      },
      displayBankBanner() {
        if (this.newBankBannerLogo) {
          return `data:image/jpeg;base64,${this.newBankBannerLogo}`;
        } else if (this.editedItem.bank_banner_logo) {
          return `data:image/jpeg;base64,${this.editedItem.bank_banner_logo}`;
        }
        return null;
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    data() {
      return {
        dialog: false,
        busy: false,
        search: '',
        bankList: [],
        editedIndex: -1,
        editedItem: {
          bank_id: null,
          bank_code: null,
          country_code: null,
          location_code: null,
          branch_code: null,
          bank_name: null,
          bank_enabled: false,
          currency_code: null,
          max_amt_per_trx: null,
          bank_banner_colour: this.$vuetify.theme.currentTheme.primary,
          bank_banner_logo: '',
          bank_url: '',
          paytype: [],
        },
        defaultItem: {
          bank_id: '',
          bank_code: '',
          country_code: '',
          location_code: '',
          branch_code: '',
          bank_name: '',
          bank_enabled: false,
          currency_code: null,
          max_amt_per_trx: '',
          bank_banner_colour: this.$vuetify.theme.currentTheme.primary,
          bank_banner_logo: '',
          bank_url: '',
          paytype: [],
        },
        bank_banner_img: null,
        colorPicker: {
          hex: this.$vuetify.theme.currentTheme.primary
        },
        colorPickerMenu: false,
        newBankBannerLogo: null,
        supported_paytype: PayType.map(x => x.code),
      }
    },
    methods: {
      async fetch () {
        this.busy = true;
        const _this = this;
        await axios.get('/app/api/bank')
          .then(function (response) {
            _this.bankList = response.data.data;
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      editItem (item) {
        this.editedIndex = this.bankList.indexOf(item)
        this.editedItem = Object.assign({}, item)


        this.dialog = true
      },
      deleteItem (item) {
        if (confirm(this.$t('delete_confirmation'))) {
          this.busy = true;
          const _this = this;
          axios.delete(`/app/api/bank/${item.bank_id}`)
            .then(function (response) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: response.data.message,
                color: 'success',
              })
              _this.fetch();
            })
            .catch(function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: error.response.data.message,
                color: 'error',
              })
            })
            .finally(function () {
              _this.busy = false;
            })
        }
      },
      close () {
        this.dialog = false
        // setTimeout(() => {

        this.newBankBannerLogo = null;
        this.bank_banner_img = null;
        this.colorPicker = {
          hex: '#000000'
        };
        this.colorPickerMenu = false

        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        // }, 300)
      },
      save () {
        if (this.editedIndex > -1) {
          this.updateBank();
        } else {
          this.addBank();
        }
      },
      updateBank () {
        this.busy = true;
        const _this = this;

        const data = {
          bank_code: this.editedItem.bank_code,
          country_code: this.editedItem.country_code,
          location_code: this.editedItem.location_code,
          branch_code: this.editedItem.branch_code,
          bank_name: this.editedItem.bank_name,
          bank_enabled: this.editedItem.bank_enabled,
          currency_code: this.editedItem.currency_code,
          max_amt_per_trx: this.editedItem.max_amt_per_trx,
          bank_banner_colour: this.editedItem.bank_banner_colour,
          bank_url: this.editedItem.bank_url ? this.editedItem.bank_url : null,
          paytype: this.editedItem.paytype,
        };
        if (this.newBankBannerLogo) {
          data.bank_banner_logo = this.newBankBannerLogo;
        }
        axios.put(`/app/api/bank/${this.editedItem.bank_id}`, data)
          .then(function () {
            _this.fetch();
            _this.close()
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      addBank () {
        this.busy = true;
        const _this = this;

        const data = {
          bank_code: this.editedItem.bank_code,
          country_code: this.editedItem.country_code,
          location_code: this.editedItem.location_code,
          branch_code: this.editedItem.branch_code,
          bank_name: this.editedItem.bank_name,
          bank_enabled: this.editedItem.bank_enabled,
          currency_code: this.editedItem.currency_code,
          max_amt_per_trx: this.editedItem.max_amt_per_trx,
          bank_banner_colour: this.editedItem.bank_banner_colour,
          bank_url: this.editedItem.bank_url ? this.editedItem.bank_url : null,
          paytype: this.editedItem.paytype,
        };
        if (this.newBankBannerLogo) {
          data.bank_banner_logo = this.newBankBannerLogo;
        }
        axios.post('/app/api/bank', data)
          .then(function () {
            _this.fetch();
            _this.close();
          })
          .catch(function (error) {
            // Show error.
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      updateColor(isMenuOpen) {
        // eslint-disable-next-line
        // console.log(isMenuOpen)
        if (isMenuOpen) {
          this.colorPicker = this.editedItem.bank_banner_colour
        } else {
          // menu close
          // eslint-disable-next-line
          // console.log(this.colorPicker)
          this.editedItem.bank_banner_colour = this.colorPicker ?
            this.colorPicker.hex ? this.colorPicker.hex : this.colorPicker : this.editedItem.bank_banner_colour
        }
      },
      convertToBase64() {
        // eslint-disable-next-line
        console.log(this.bank_banner_img)

        const _this = this;

        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(this.bank_banner_img);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          _this.$store.dispatch('snackbar/openSnackbar', {
            message: error,
            color: 'error',
          })
        };
        reader.onloadend = function () {
          _this.newBankBannerLogo = imgResult.split(',').pop();
        };
      },
      clearBankBannerLogo() {
        this.newBankBannerLogo = null;
        this.editedItem.bank_banner_logo = null;
      }
    },
  }
</script>
