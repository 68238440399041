<template>
  <v-container>
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{$t('balance_history')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="download">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </v-toolbar>

      <div>
        <v-card-title>
          {{$t('record')}}
          <v-spacer></v-spacer>
          <v-row>
            <v-col>
              <v-datetime-picker
                v-model="fromDate"
                :text-field-props="{prependIcon: 'mdi-calendar'}"
                :label="$t('date_from')"
                :ok-text="$t('confirm')"
                :clear-text="$t('clear')"
                @input="fetch()"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col>
              <v-datetime-picker
                v-model="toDate"
                :text-field-props="{prependIcon: 'mdi-calendar'}"
                :label="$t('date_to')"
                :ok-text="$t('confirm')"
                :clear-text="$t('clear')"
                @input="fetch()"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col>
              <v-combobox
                :label="$t('user')"
                :items="userList"
                item-text="display_name"
                item-value="user_id"
                :return-object="false"
                v-model="userFilter"
                clearable
              >
                <template v-slot:item="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.user_id }} - {{ data.item.display_name }}
                </template>
              </v-combobox>
            </v-col>
            <v-col>
              <v-combobox
                :label="$t('currency')"
                :items="CurrencyList"
                item-text="currency_code"
                item-value="currency_code"
                :return-object="false"
                v-model="currencyFilter"
                clearable
                @change="fetch()"
              >
              </v-combobox>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="recordList"
          :page="page"
          :pageCount="numberOfPages"
          :options.sync="options"
          :multi-sort="true"
          :must-sort="false"
          :footer-props="{
            'show-current-page': true,
            'items-per-page-options': rowsPerPageItem,
            'show-first-last-page': true,
          }"
          :server-items-length="totalRecords"
          :loading="loading"
        >
          <template v-slot:footer.prepend>
            <ValidationObserver ref="pageInputObserver" v-slot="{ validate, reset }">
              <ValidationProvider name="page" :rules="{integer: true, min_value: 1, max_value: numberOfPages}" v-slot="{ errors }">
                <v-text-field
                  v-model.number="pageInput"
                  type="number"
                  append-outer-icon="mdi-chevron-right-circle-outline"
                  @click:append-outer="jumpToPage"
                  :label="$t('Go to page')"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </ValidationObserver>
          </template>

          <template v-slot:item.transaction_time="{ item }">
            {{ new Date(item.transaction_time).toLocaleString() }}
          </template>
          <template v-slot:item.user_id="{ item }">
            <router-link v-if="item.user_id" :to="{name: 'balanceHistory', params: { user: item.user_id}}">{{item.user_id}}</router-link>
          </template>
          <template v-slot:item.transaction_type="{ item }">
            {{ $t(getKeyByValue(TransactionType, item.transaction_type)) }}
          </template>
          <template v-slot:item.transaction_status="{ item }">
            <span :class="getStatusColor(item.transaction_status, item.assignee)">
              {{ getStatusType(item.transaction_status, item.assignee) }}
            </span>
          </template>
          <template v-slot:item.amount="{ item }">
            {{ item.amount.toLocaleString(undefined, {minimumFractionDigits: 2}) }}
          </template>
          <template v-slot:item.balance="{ item }">
            {{ item.balance.toLocaleString(undefined, {minimumFractionDigits: 2}) }}
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" @click="fetch()">{{$t('refresh')}}</v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import transactionMixins from '../../mixins/transactionMixins'
  import contactMixins from '../../mixins/contactMixins'
  import currencyMixins from '../../mixins/currencyMixins'
  import { integer, min_value, max_value } from 'vee-validate/dist/rules';
  import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
  import FileDownload from 'js-file-download';

  extend('integer', integer);
  extend('min_value', min_value);
  extend('max_value', max_value);

  export default {
    name: 'ClientBalanceHistory',
    mixins: [transactionMixins, contactMixins, currencyMixins],
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    computed: {
      ...mapGetters({
        downloadLocale: 'lang/locale',
      }),
      headers () {
        return [
          {text: this.$t('creation_time'), align: 'left', value: 'transaction_time', sortable: false},
          {text: this.$t('transaction_number'), value: 'transaction_number', sortable: false},
          {text: this.$t('user_id'), value: 'user_id', sortable: false},
          {text: this.$t('Client Name'), value: 'display_name', sortable: false},
          {text: this.$t('transaction_type'), value: 'transaction_type', sortable: false},
          // {text: this.$t('transaction_status'), value: 'transaction_status', sortable: false},
          {text: this.$t('currency'), value: 'currency_code', sortable: false},
          {text: this.$t('amount'), value: 'amount', sortable: false},
          {text: this.$t('merchant_balance'), value: 'balance', sortable: false},
        ];
      },
    },
    watch: {
      options: {
        handler() {
          this.fetch();
        },
      },
      '$route.params.user': function (value) {
        this.userFilter = value;
        this.resetOptions();
      },
      userFilter (value) {
        this.$router.push({name: 'balanceHistory', params: {
          user: value,
        }});
      },
      deep: true,
    },
    mounted () {
      this.fetchUsers();
      this.fetchCurrency();
    },
    data: function() {
      return {
        pageInput: '',
        page: 1,
        totalRecords: 0,
        numberOfPages: 0,
        options: {
          page: 1,
          itemsPerPage: 50,
          sortBy: ['creation_time'],
          sortDesc: [true],
          multiSort: true,
          mustSort: false,
        },
        loading: false,
        rowsPerPageItem: [50, 100, 200],
        dialog: false,
        busy: false,
        fromDate: null,
        toDate: null,
        recordList: [],
        userList: [],
        userFilter: this.$route.params.user,
        currencyFilter: null,
      }
    },
    methods: {
      async download() {
        await this.fetch(true);
      },
      async fetch(download = false) {
        const _this = this;

        const {sortBy, sortDesc, page, itemsPerPage} = this.options;
        let pageNumber = page;

        let endpoint = '/app/api/report/client/balance';
        if (this.$route.params.user) {
          endpoint = `/app/api/report/client/balance/${this.$route.params.user}`;
        }

        let params = {
          params: {
            page: pageNumber,
            count: itemsPerPage,
            tz: new Date().getTimezoneOffset(),
            sortBy: sortBy,
            sortDesc: sortDesc,
            currency: (
              this.currencyFilter !== undefined && this.currencyFilter !== null 
                ? this.currencyFilter 
                : undefined
            ),
          }
        };
        if (this.fromDate) {
          const fromDate = Date.parse(this.fromDate);

          if (!isNaN(fromDate)) {
            params.params.fromDate = fromDate;
          }
        }
        if (this.toDate) {
          const toDate = Date.parse(this.toDate);

          if (!isNaN(toDate)) {
            params.params.toDate = toDate;
          }
        }

        if (download) {
          params.params.download = true;
          params.params.locale = this.downloadLocale;
          params.responseType = 'blob';
          await axios.get(endpoint, params)
            .then((response) => {
              FileDownload(response.data, 'report.xlsx');
            })
            .catch(async function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: JSON.parse(await error.response.data.text()).message,
                color: 'error',
              })
            });
        } else {
          this.busy = true;
          this.loading = true;
          await axios.get(endpoint, params)
            .then(function (response) {
              _this.recordList = response.data.data.data;
              _this.totalRecords = response.data.data.total;
              _this.numberOfPages = response.data.data.total_page;
            })
            .catch(function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: error.response.data.message,
                color: 'error',
              })
            })
            .finally(function () {
              _this.loading = false;
              _this.busy = false;
            })
        }
      },
      resetOptions () {
        this.page = 1;
        this.totalRecords = 0;
        this.numberOfPages = 0;
        this.options = {
          page: 1,
          itemsPerPage: 50,
          sortBy: ['creation_time'],
          sortDesc: [true],
          multiSort: true,
          mustSort: false,
        };
      },
      jumpToPage () {
        this.$refs.pageInputObserver.validate().then(result => {
          if (result && this.pageInput) {
            this.page = this.pageInput;
            this.pageInput = '';
          }
        });
      },
      async fetchUsers () {
        this.busy = true;
        const _this = this;
        await axios.get('/app/api/client')
          .then(function (response) {
            _this.userList = response.data.data.filter(user => user.user_type === _this.UserType.CLIENT);
            _this.userList.splice(0, 0, {display_name: 'ROOT', user_id: 1});
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      }
    }
  }
</script>
