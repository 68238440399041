import {REMIT} from '../constants/ting'

let tingMixins = {
  data: () => ({
    PayTypes: [
      {code:  REMIT, name: '银行卡转账'},
      // {code:  ting.QRCODE, name: '扫码'},
      // {code:  ting.ONLINE, name: '网银支付'},
      // {code:  ting.QUICK, name: '快捷支付'},
    ],
  }),
  methods: {
    customFilter(item, queryText, itemText) { // eslint-disable-line no-unused-vars
      const textOne = item.code.toLowerCase()
      const textTwo = item.name.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    },
    findNameByCode(code) {
      try {
        let name = this.Banks.find(x => x.code === code).name
        return name ? name : code
      } catch {
        return code
      }
    }
  },
}

export default tingMixins;
