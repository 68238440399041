<template>
  <form>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('client_configure_trx_cfg') }}</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="close"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-card
            v-for="(item, itemIdx) in editedItem.trx_cfg"
            :key="item.method"
            rounded="100"
            density="compact"
            class="mt-3"
          >
            <v-card-title>{{ item.comm_type }}</v-card-title>
            <v-card-text>
              <v-row
                v-for="(trx_cfg_cyc, idx) in item.trx_cfg_cyc"
                :key="
                  idx.toString() + '-' 
                    + trx_cfg_cyc.is_default + '-' 
                    + trx_cfg_cyc.currency_code
                "
              >
                <v-col cols="12" sm="1" align-self="center">
                  <v-btn 
                    v-if="!trx_cfg_cyc.is_default"
                    icon 
                    @click="removeTrxCfg(itemIdx, idx)">
                    <v-icon>
                      mdi-delete-alert
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-combobox
                    v-if="!trx_cfg_cyc.is_default"
                    :label="$t('currency')"
                    :items="CurrencyList"
                    item-text="currency_code"
                    item-value="currency_code"
                    :return-object="false"
                    v-model="trx_cfg_cyc.currency_code"
                  >
                  </v-combobox>
                  <v-text-field
                    v-if="trx_cfg_cyc.is_default"
                    :label="$t('default')"
                    disabled
                  />
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                    :label="$t('base_rate')"
                    v-model.number="trx_cfg_cyc.base_rate"
                    name="base_rate"
                    type="number"
                  />
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                    :label="$t('payment_rate')"
                    v-model.number="trx_cfg_cyc.rate"
                    name="payment_rate"
                    type="number"
                  />
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                    :label="$t('min_tx_amt')"
                    v-model.number="trx_cfg_cyc.min_amt"
                    name="base_rate"
                    type="number"
                  />
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                    :label="$t('max_tx_amt')"
                    v-model.number="trx_cfg_cyc.max_amt"
                    name="base_rate"
                    type="number"
                  />
                </v-col>
                <v-col cols="12" sm="2" offset-sm="4">
                  <v-text-field
                    :label="$t('min_base_comm_amt')"
                    v-model.number="trx_cfg_cyc.min_base_comm_amt"
                    name="min_base_comm_amt"
                    type="number"
                  />
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                    :label="$t('min_comm_amt')"
                    v-model.number="trx_cfg_cyc.min_comm_amt"
                    name="min_comm_amt"
                    type="number"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    :label="$t('cvr_mode')"
                    name="cvr_mode"
                    :items="supportedCvrMode"
                    v-model="trx_cfg_cyc.cvr_mode"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn color="blue darken-1" text @click="addNewTrxCfg(itemIdx)">
                {{$t('add')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">{{$t('cancel')}}</v-btn>
        <v-btn color="blue darken-1" text @click="save" :loading="busy">{{$t('save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </form>
</template>

<script>
  import contactMixins from "../../mixins/contactMixins";
  import clientMixins from "./clientMixins";
  import currencyMixins from "../../mixins/currencyMixins";
  import DefClient from "../../constants/DefClient";

  export default {
    name: 'ClientTrxCfgForm',
    props: ['defaultItem', 'editedIndex', 'dialogVisible'],
    mixins: [contactMixins, clientMixins, currencyMixins],

    watch: {
      dialogVisible: {
        immediate: true,
        handler(visible) {
          if (visible) {
            this.fetchCurrency();

            // Add a default profile if doesnt exist

            for (let i = 0; i < this.editedItem.trx_cfg.length; i++) {
              if (
                this.editedItem.trx_cfg[i].trx_cfg_cyc
                  .filter(x => x.is_default).length === 0
              ) {
                this.addTrxCfg(i, true);
              }
            }
          }
        }
      }
    },
    data: function() {
      return {
        supportedCvrMode: Object
          .entries(DefClient.CvrMode)
          .map(x => { return { value: x[1], text: x[0] }; }),
      }
    },
    methods: {
      addTrxCfg(commTypeIdx, addDflt) {
        if (commTypeIdx < 0 || commTypeIdx >= this.editedItem.trx_cfg.length) {
          return;
        }

        const trxCfgCyc = {
          is_default: addDflt,
          currency_code: null,
          min_amt: 0,
          max_amt: 0,
          base_rate: 0,
          rate: 0,
          min_base_comm_amt: 0,
          min_comm_amt: 0,
          cvr_mode: 0,
        };

        // Because editedItem is deep clone so here can freely modify, otherwise
        // cannot modify editedItem directly

        if (addDflt) {
          this.editedItem.trx_cfg[commTypeIdx].trx_cfg_cyc.unshift(trxCfgCyc);
        }
        else {
          this.editedItem.trx_cfg[commTypeIdx].trx_cfg_cyc.push(trxCfgCyc);
        }
      },
      addNewTrxCfg(idx) {
        this.addTrxCfg(idx, false);
      },
      removeTrxCfg(commTypeIdx, idx) {
        if (commTypeIdx < 0 || commTypeIdx >= this.editedItem.trx_cfg.length) {
          return;
        }

        if (
          idx < 0 || 
          idx >= this.editedItem.trx_cfg[commTypeIdx].trx_cfg_cyc.length
        ) {
          return;
        }

        if (this.editedItem.trx_cfg[commTypeIdx].trx_cfg_cyc[idx].is_default) {
          return;
        }

        this.editedItem.trx_cfg[commTypeIdx].trx_cfg_cyc.splice(idx, 1);
      },
      fetch() {
        this.$emit('fetch');
      },
      close () {
        this.$emit('close');
      },
      save () {
        this.updateUser();
      },
    }
  }
</script>
