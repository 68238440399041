<template>
  <form>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="close"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('username')"
                v-model="editedItem.username"
                name="username"
                type="text"
                :disabled="editedIndex > -1"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                id="password"
                v-model="editedItem.password"
                :label="$t('password')"
                name="password"
                type="password"
                :disabled="editedIndex > -1"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('email')"
                v-model="editedItem.email"
                name="email"
                type="text"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('phone')"
                :prefix="`+${editedItem.phone_code}`"
                v-model="editedItem.phone_number"
                name="phone"
                prepend-icon="mdi-phone"
                type="text"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('display_name')"
                v-model="editedItem.display_name"
                name="display_name"
                type="text"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('address')"
                v-model="editedItem.address"
                name="address"
                type="text"
              />
            </v-col>

            <v-col cols="12" sm="12" v-if="editedItem.user_type === UserType.CLIENT">
              <v-text-field
                :label="$t('client_name')"
                v-model="editedItem.client_name"
                name="client_name"
                type="text"
              />
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="editedItem.ena_login_whitelist_ip"
                :label="$t('ena_login_whitelist_ip')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="editedItem.login_whitelist_ip"
                :label="$t('login_whitelist_ip')"
                multiple
                chips
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('press_key') }} <kbd>enter</kbd> {{ $t('to_create') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="12" v-if="editedItem.user_type === UserType.CLIENT">
              <v-combobox
                v-model="editedItem.api_whitelist_ip"
                :label="$t('api_whitelist_ip')"
                multiple
                chips
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('press_key') }} <kbd>enter</kbd> {{ $t('to_create') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" v-if="editedItem.user_type === UserType.CLIENT">
              <v-checkbox
                v-model="editedItem.ena_collect_trx_amt_adj"
                :label="$t('ena_collect_trx_amt_adj')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" v-if="editedItem.user_type === UserType.CLIENT">
              <v-checkbox
                v-model="editedItem.ena_org_amt_comm_calc"
                :label="$t('ena_org_amt_comm_calc')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" v-if="editedItem.user_type === UserType.CLIENT">
              <v-checkbox
                v-model="editedItem.ena_collect_tobankcode"
                :label="$t('ena_collect_tobankcode')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" v-if="editedItem.user_type === UserType.CLIENT">
              <v-checkbox
                v-model="editedItem.ena_ignore_tp_reject_callback"
                :label="$t('ena_ignore_tp_reject_callback')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-select
                :label="$t('api_version')"
                name="api_version"
                :items="supportedApiVersion"
                v-model="editedItem.api_version"
              >
              </v-select>
            </v-col>
            <v-col cols="12" v-if="editedItem.user_type === UserType.CLIENT">
              <v-combobox
                v-model="editedItem.cust_blacklist_ip"
                :label="$t('cust_blacklist_ip')"
                multiple
                chips
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('press_key') }} <kbd>enter</kbd> {{ $t('to_create') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">{{$t('cancel')}}</v-btn>
        <v-btn color="blue darken-1" text @click="save" :loading="busy">{{$t('save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </form>
</template>

<script>
  import contactMixins from "../../mixins/contactMixins";
  import clientMixins from "./clientMixins";
  import DefClient from "../../constants/DefClient";

  export default {
    name: 'ClientForm',
    props: ['formTitle', 'editedIndex'],
    mixins: [contactMixins, clientMixins],
    computed: {
      supportedApiVersion() {
        return DefClient.API_VERSION.map(
          x => ({ 
            value: x, 
            text: `V${x} - ` + this.$t(DefClient.ApiVersionDesc[x]),
          })
        )
      },
    },
    methods: {
      fetch() {
        this.$emit('fetch');
      },
      close () {
        this.$emit('close');
      },
      save () {
        if (this.editedIndex > -1) {
          this.updateUser();
        } else {
          this.addUser();
        }
      },
    }
  }
</script>
