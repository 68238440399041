<template>
  <v-container>
    <v-card>
      <v-toolbar
        color="primary"
        dark
        flat
      >
        <v-toolbar-title>{{$t('contact')}}</v-toolbar-title>
        <v-spacer></v-spacer>
<!--        <v-btn icon :to="{name: 'contact.insert'}">-->
<!--          <v-icon>mdi-plus-circle</v-icon>-->
<!--        </v-btn>-->

        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs
            align-with-title
            background-color="transparent"
          >
            <v-tabs-slider color="white"></v-tabs-slider>

            <v-tab v-for="(item, index) in items" :to="item.route" :key="index">
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <router-view></router-view>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'Contact',
    computed: {
      items () {
        return [
          {
            name: this.$t('client'),
            route: {name: 'contact.client'}
          },
          {
            name: this.$t('staff'),
            route: {name: 'contact.staff'}
          },
          // {
          //   name: this.$t('cs'),
          //   route: {name: 'contact.cs'}
          // },
          // {
          //   name: this.$t('client'),
          //   route: {name: 'contact.client'}
          // },
        ]
      }
    }
  }
</script>
