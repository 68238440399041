<template>
  <v-container>
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{$t('sys_err_log')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="toggleSound">
          <v-icon v-if="isMute">mdi-volume-off</v-icon>
          <v-icon v-else>mdi-volume-high</v-icon>
        </v-btn>
      </v-toolbar>
      <div>
        <v-data-table
          :headers="headers"
          :items="sysErrLogList"
          :page="page"
          :pageCount="numberOfPages"
          :options.sync="options"
          :disable-sort="true"
          :footer-props="{
            'show-current-page': true,
            'items-per-page-options': rowsPerPageItem,
            'show-first-last-page': true,
          }"
          :server-items-length="totalSysErrLog"
          :loading="loading"
        >
          <template v-slot:item.creation_time="{ item }">
            {{ new Date(item.creation_time).toLocaleString() }}
          </template>
          <template v-slot:item.req_http_path_code="{ item }">
            {{ 
              computeReqHttpPathFromCode(
                item.req_http_path_code, item.req_http_path
              ) 
            }}
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex'

  export default {
    name: 'SystemErrorLog',
    computed: {
      ...mapGetters({
        isRoot: 'auth/isRoot',
        isManager: 'auth/isManager',
        isCsAgent: 'auth/isCsAgent',
        isClient: 'auth/isClient',
        isClientCsa: 'auth/isClientCsa',
        isMute: 'auth/isSysErrLogMute',
        hasNewSysErrLog: 'auth/hasNewSysErrLog',
      }),
      headers() {
        return [
          { 
            text: this.$t('system_error_log_time'), 
            align: 'left', 
            value: 'creation_time',
          },
          { 
            text: this.$t('system_error_log_code'), 
            value: 'code'
          },
          { 
            text: this.$t('system_error_log_message'), 
            value: 'msg'
          },
          { 
            text: this.$t('user_id'), 
            value: 'user_id'
          },
          { 
            text: this.$t('client_ip_addr'), 
            value: 'ip_addr'
          },
          { 
            text: this.$t('req_http_method'), 
            value: 'req_http_method'
          },
          { 
            text: this.$t('req_http_path_code'), 
            value: 'req_http_path_code'
          },
          { 
            text: this.$t('rsp_http_code'), 
            value: 'rsp_http_code'
          },
        ]
      },
    },
    mounted() {
      this.$store.dispatch('auth/setSysErrLogNewItem', false);
      this.fetch();
      this.$watch(
        (vm) => [ vm.options ],
        () => { this.fetch(); },
        {
          immediate: true,
          // deep: true,
        }
      );
      this.$watch(
        (vm) => vm.hasNewSysErrLog,
        (newValue) => { 
          if (newValue) {
            this.fetch();
          } 
        },
        {
          immediate: true,
          // deep: true,
        }
      );
    },
    data: function() {
      return {
        page: 1,
        numberOfPages: 0,
        rowsPerPageItem: [50,100,200],
        totalSysErrLog: 0,
        sysErrLogList: [],
        options: {
          page: 1,
          itemsPerPage: 50,
        },
        sys_err_log_type: 2,
        loading: false,
      };
    },
    methods: {
      toggleSound() {
        this.$store.dispatch('auth/setSysErrLogMute');
      },
      fetch() {
        const _this = this;
        
        const endpoint = '/app/api/syserrlog'
        const { page, itemsPerPage } = this.options;
        const params = {
          params: {
            page,
            count: itemsPerPage,
            type: this.sys_err_log_type,
          }
        };

        this.loading = true;

        axios
          .get(endpoint, params)
          .then(function (response) {
            _this.sysErrLogList = response.data.data.data;
            _this.totalSysErrLog = response.data.data.total;
            _this.numberOfPages = response.data.data.total_page;
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(() => { _this.loading = false; })
      },
      computeReqHttpPathFromCode(code, path) {
        if (code === 0) {
          return this.$t('system_error_log_http_path_collect');
        }
        else if (code === 1) {
          return this.$t('system_error_log_http_path_collect_ui');
        }
        else if (code === 2) {
          return this.$t('system_error_log_http_path_pay');
        }
        else if (code === 3) {
          return this.$t('system_error_log_http_path_order_enquiry');
        }
        else if (code === 4) {
          return this.$t('system_error_log_http_path_balance_enquiry');
        }
        else {
          return path
        }
      }
    },
  }
</script>
