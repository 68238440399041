<template>
  <v-container>
    <v-card>
      <v-toolbar
        color="primary"
        dark
        flat
      >
        <v-toolbar-title>{{$t('service_provider')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar>

      <div>
        <v-card-title>
          {{$t('all')}}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="serviceProviderList"
          :search="search"
        >
          <template v-slot:top>
<!--            <v-toolbar flat>-->
<!--              <v-toolbar-title>{{$t('client')}}</v-toolbar-title>-->
<!--              <v-spacer></v-spacer>-->
              <v-dialog v-model="dialog" max-width="500px">
<!--                <template v-slot:activator="{ on }">-->
<!--                  <v-btn color="primary" dark class="mb-2" v-on="on">{{$t('new_client')}}</v-btn>-->
<!--                </template>-->
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      @click="close"
                    >
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            :label="$t('service_provider')"
                            v-model="editedItem.name"
                            name="name"
                            type="text"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            :label="$t('description')"
                            v-model="editedItem.desc"
                            name="description"
                            type="text"
                          />
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row
                        v-for="(item) in editedItem.commission"
                        :key="item.method.id"
                      >
                        <v-col cols="12" sm="4">
                          <v-text-field
                            :label="$t('payment_method')"
                            v-model="item.method"
                            name="payment_method"
                            type="text"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            :label="$t('base_rate')"
                            v-model.number="item.base_rate"
                            name="base_rate"
                            type="text"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            :label="$t('payment_rate')"
                            v-model.number="item.rate"
                            name="payment_rate"
                            type="text"
                          />
                        </v-col>
<!--                        <v-col cols="12" sm="4">-->
<!--                          <v-text-field-->
<!--                            :label="$t('payment_rate')"-->
<!--                            v-model.number="item.rate"-->
<!--                            append-outer-icon="mdi-close"-->
<!--                            @click:append-outer="remove(index)"-->
<!--                            name="payment_rate"-->
<!--                            type="text"-->
<!--                          />-->
<!--                        </v-col>-->
                      </v-row>
<!--                      <v-row>-->
<!--                        <v-col cols="12" class="text-center">-->
<!--                          <v-btn color="primary" @click="add">-->
<!--                            <span class="mr-2">{{$t('add')}}</span>-->
<!--                            <v-icon>mdi-plus-circle</v-icon>-->
<!--                          </v-btn>-->
<!--                        </v-col>-->
<!--                      </v-row>-->
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{$t('cancel')}}</v-btn>
                    <v-btn color="blue darken-1" text @click="save" :loading="busy">{{$t('save')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
<!--            </v-toolbar>-->
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
<!--            <v-icon-->
<!--              small-->
<!--              @click="deleteItem(item)"-->
<!--            >-->
<!--              mdi-delete-->
<!--            </v-icon>-->
          </template>
        </v-data-table>
      </div>

    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'ServiceProvider',
    mounted() {
      this.fetch();
    },
    computed: {
      headers() {
        return [
          {
            text: this.$t('service_provider'),
            align: 'left',
            sortable: true,
            value: 'name',
          },
          {
            text: this.$t('description'),
            align: 'left',
            sortable: true,
            value: 'desc',
          },
          {
            text: this.$t('actions'),
            align: 'right',
            value: 'action',
            sortable: false
          },
        ]
      },
      formTitle () {
        return this.editedIndex === -1 ? this.$t('new_service_provider') : this.$t('edit_service_provider')
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    data: () => ({
      counterId: 1,
      dialog: false,
      busy: false,
      search: '',
      editedIndex: -1,
      editedId: -1,
      editedItem: {
        name: '',
        desc: '',
        commission: [],
      },
      defaultItem: {
        name: '',
        desc: '',
        commission: [],
      },
      defaultCommissionItem: {
        method: '',
        base_rate: '',
        rate: '',
      },
      serviceProviderList: [],
    }),
    methods: {
      async fetch () {
        this.busy = true;
        const _this = this;
        await axios.get('/app/api/gateway')
          .then(function (response) {
            _this.serviceProviderList = response.data.data;
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      editItem (item) {
        this.editedIndex = this.serviceProviderList.indexOf(item)
        this.editedId = item.gateway_id
        // this.editedItem = Object.assign({}, item) out of memory
        this.editedItem.name = item.name
        this.editedItem.desc = item.desc
        for (let i = 0; i < item.commission.length; i++) {
          this.editedItem.commission.push({
            id: this.counterId++,
            method: item.commission[i].method,
            base_rate: item.commission[i].base_rate,
            rate: item.commission[i].rate,
          })
        }
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.editedItem.name = this.defaultItem.name
        this.editedItem.desc = this.defaultItem.desc
        this.editedItem.commission = []
        this.editedIndex = -1
        this.counterId = -1
        this.editedId = -1
      },
      save () {
        if (this.editedIndex > -1) {
          this.updateServiceProvider();
        } else {
          this.addServiceProvider();
        }
      },
      updateServiceProvider () {
        this.busy = true;
        const _this = this;

        let payload = {
          name: this.editedItem.name,
          desc: this.editedItem.desc,
          commission: [],
        }
        for (let i = 0; i < this.editedItem.commission.length; i++) {
          payload.commission.push({
            method: this.editedItem.commission[i].method,
            base_rate: this.editedItem.commission[i].base_rate,
            rate: this.editedItem.commission[i].rate,
          })
        }

        axios.put(`/app/api/gateway/${this.editedId}`, payload)
          .then(function () {
            _this.fetch();
            _this.close()
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      addServiceProvider () {
        this.busy = true;
        const _this = this;
        axios.post('/app/api/gateway', {

        })
          .then(function () {
            _this.fetch();
            _this.close();
          })
          .catch(function (error) {
            // Show error.
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      add () {
        this.editedItem.commission.push({
          id: this.counterId++,
          method: this.defaultCommissionItem.method,
          base_rate: this.defaultCommissionItem.base_rate,
          rate: this.defaultCommissionItem.rate,
        })
      },
      remove (index) {
        this.editedItem.commission.splice(index, 1)
      }
    }
  }
</script>
