<template>
  <form>
    <v-card>
      <v-card-title>
        <span class="headline"> {{ $t('bank_card_list') }}</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="close"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :loading="bankCardsLoading"
          :headers="headers"
          :items="bankCardList"
          v-model="formattedBankCards"
          item-key="bank_card_id"
          show-select
        >
          <template v-slot:item.max_daily_tx_amt="{ item }">
            {{ item.max_daily_tx_amt ? item.max_daily_tx_amt.toLocaleString(undefined, {minimumFractionDigits: 2}) : ''}}
          </template>
          <template v-slot:item.max_tx_limit="{ item }">
            {{ item.max_tx_limit ? item.max_tx_limit.toLocaleString() : '' }}
          </template>
          <template v-slot:item.max_hourly_tx_limit="{ item }">
            {{ item.max_hourly_tx_limit ? item.max_hourly_tx_limit.toLocaleString() : ''}}
          </template>
          <template v-slot:item.min_tx_amt="{ item }">
            {{ item.min_tx_amt ? item.min_tx_amt.toLocaleString(undefined, {minimumFractionDigits: 2}) : '' }}
          </template>
          <template v-slot:item.max_tx_amt="{ item }">
            {{ item.max_tx_amt ? item.max_tx_amt.toLocaleString(undefined, {minimumFractionDigits: 2}) : ''}}
          </template>
          <template v-slot:item.assign_client="{ item }">
            <v-tooltip v-if="item.assign_client" bottom>
              <template v-slot:activator="{ on, attrs }">
                <a
                  :href="item.assign_client ? 'javascript:void(0);' : false"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.assign_client.length }}
                </a>
              </template>
              <div v-if="item.assign_client.length === 0">
                {{$t('no_assigned_clients')}}
              </div>
              <div v-else>
                <div :key="id" v-for="id in item.assign_client">
                  {{ id }}
                </div>
              </div>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="fetchBankCards">{{$t('refresh')}}</v-btn>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">{{$t('cancel')}}</v-btn>
        <v-btn color="blue darken-1" text @click="save" :loading="busy">{{$t('save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </form>
</template>

<script>
  import contactMixins from "../../mixins/contactMixins";
  import clientMixins from "./clientMixins";

  export default {
    name: 'ClientBankCard',
    props: ['bankCardList', 'bankCardsLoading'],
    mixins: [contactMixins, clientMixins],
    computed: {
      headers () {
        return [
          {
            text: this.$t('bank_card_id'),
            align: 'left',
            sortable: true,
            value: 'bank_card_id',
          },
          { text: this.$t('bank_card_bank_code'), value: 'bank_card_bank_code' },
          { text: this.$t('bank_card_name'), value: 'bank_card_name' },
          { text: this.$t('bank_card_number'), value: 'bank_card_number' },
          { text: this.$t('bank_card_enabled'), value: 'bank_card_enabled' },

          { text: this.$t('max_daily_tx_amt'), value: 'max_daily_tx_amt' },
          { text: this.$t('max_tx_limit'), value: 'max_tx_limit' },
          { text: this.$t('max_hourly_tx_limit'), value: 'max_hourly_tx_limit' },
          { text: this.$t('min_tx_amt'), value: 'min_tx_amt' },
          { text: this.$t('max_tx_amt'), value: 'max_tx_amt' },

          { text: this.$t('assigned_clients'), value: 'assign_client' },
        ];
      },
      formattedBankCards: {
        get() {
          return this.bankCardList.filter(item => this.editedItem.assign_bankcard.includes(item.bank_card_id));
        },
        set(newArray) {
          this.editedItem.assign_bankcard = newArray.map(function(item) {
            return item['bank_card_id'];
          });
        },
      }
    },
    methods: {
      fetch() {
        this.$emit('fetch');
      },
      close () {
        this.selected = [];
        this.$emit('close');
      },
      fetchBankCards () {
        this.$emit('fetchBankCards');
      },
      save () {
        this.updateUser();
      },
    }
  }
</script>
