<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="close"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-if="editedItem.client_order_id"
              :label="$t('client_order_id')"
              v-model="editedItem.client_order_id"
              type="text"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-if="editedItem.paytype"
              :label="$t('paytype')"
              v-model="editedItem.paytype"
              type="text"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <template v-if="editedItem.bank">
            <v-col cols="6" sm="3">
              <v-text-field
                :label="$t('bank_code')"
                v-model="editedItem.bank.bank"
                name="bank_code"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                :label="$t('country_code')"
                v-model="editedItem.bank.country_code"
                name="country_code"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                :label="$t('location_code')"
                v-model="editedItem.bank.location_code"
                name="location_code"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                :label="$t('branch_code')"
                v-model="editedItem.bank.branch_code"
                name="branch_code"
                type="text"
                :filled="editedIndex > -1"
                :readonly="editedIndex > -1"
              />
            </v-col>
          </template>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('amount')"
              v-model="editedItem.amount"
              name="amount"
              type="number"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('commission')"
              v-model="editedItem.commission"
              name="commission"
              type="number"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('net_amount')"
              v-model="editedItem.net_amount"
              name="net_amount"
              type="number"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('beneficiary_account')"
              v-model="editedItem.beneficiary_account"
              name="client_name"
              type="text"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <v-col cols="12" sm="12">
            <v-text-field
              :label="$t('beneficiary_name')"
              v-model="editedItem.beneficiary_name"
              name="client_name"
              type="text"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <v-col v-if="editedItem.transaction_type === TransactionType.TOP_UP || editedItem.transaction_type === TransactionType.COLLECT" cols="12" sm="12">
            <v-text-field
              :label="$t('act_bank_card_name')"
              v-model="editedItem.card.act_bank_card_name"
              name="client_name"
              type="text"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            />
          </v-col>
          <v-col cols="12" sm="12" v-if="editedItem.remark">
            <v-textarea
              :label="$t('remark_{appName}', appName)"
              v-model="editedItem.remark.user"
              name="internal_remark"
              type="text"
              auto-grow
              rows="3"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            ></v-textarea>
            <v-textarea
              :label="$t('remark_client')"
              v-model="editedItem.remark.client"
              name="client_remark"
              type="text"
              auto-grow
              rows="3"
              :filled="editedIndex > -1"
              :readonly="editedIndex > -1"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close">{{$t('continue')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import transactionMixins from '../../../mixins/transactionMixins';
  import globalMixins from '../../../mixins/globalMixins';

  export default {
    name: 'TransactionForm',
    props: ['formTitle', 'isEditable', 'editedItem', 'editedForm', 'editedIndex'],
    mixins: [transactionMixins, globalMixins],

    methods: {
      fetch() {
        this.$emit('fetch');
      },
      close() {
        this.$emit('close');
      },
    },
  }
</script>
