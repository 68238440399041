<template>
  <form v-on:submit.prevent="save">
    <v-card-text>
      <v-container>
        <ValidationObserver ref="observer" v-slot="{ validate, reset }">
          <v-row>
            <v-col cols="12">
              <ValidationProvider name="old_withdraw_key" rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors"
                  :label="$t('old_withdraw_key')"
                  name="old_withdraw_key"
                  :type="viewPassword0 ? 'text' : 'password'"
                  :append-icon="viewPassword0? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="viewPassword0 = !viewPassword0"
                  v-model="editedItem.old_withdraw_key"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <ValidationProvider name="withdraw_key" rules="required|password" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors"
                  :label="$t('new_withdraw_key')"
                  name="withdraw_key"
                  :type="viewPassword ? 'text' : 'password'"
                  :append-icon="viewPassword? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="viewPassword = !viewPassword"
                  v-model="editedItem.withdraw_key"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <ValidationProvider name="withdraw_key_confirmation" rules="required|confirmed:withdraw_key" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors"
                  :label="$t('new_withdraw_key_confirmation')"
                  name="withdraw_key_confirmation"
                  :type="viewPassword2 ? 'text' : 'password'"
                  :append-icon="viewPassword? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="viewPassword2 = !viewPassword2"
                  v-model="editedItem.withdraw_key_confirmation"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        type="submit"
        color="primary"
        :loading="busy"
      >
        {{$t('save')}}
      </v-btn>
    </v-card-actions>
  </form>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import { required, confirmed } from 'vee-validate/dist/rules';
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import i18n from '../../plugins/i18n'

  extend('required', required)
  extend('confirmed', confirmed)
  extend('password', {
    validate(value) {
      const strongRegex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})");
      return strongRegex.test(value);
    },
    message: i18n.t('v_password', {_field_: i18n.t('password')})
    // message: 'Password must be at least 8 characters long and contain at least 1 letter, 1 number, and 1 special character'
  });

  export default {
    name: 'password',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    computed: {
      ...mapGetters({
        user: 'auth/user',
        isClient: 'auth/isClient',
      }),
    },
    data: function () {
      return {
        busy: false,
        viewPassword0: false,
        viewPassword: false,
        viewPassword2: false,
        editedItem: {
          old_withdraw_key: '',
          withdraw_key: '',
          withdraw_key_confirmation: '',
        },
        defaultItem: {
          old_withdraw_key: '',
          withdraw_key: '',
          withdraw_key_confirmation: '',
        }
      }
    },
    methods: {
      save() {
        const _this = this;

        this.$refs.observer.validate().then(result => {
          // console.log(result) //eslint-disable-line

          if (result) {
            _this.busy = true;

            let endpoint = '';
            if (this.isClient) {
              endpoint = `/app/api/client/${this.user.user_id}/chgwithdrawkey`
            } else {
              endpoint = `/app/api/user/${this.user.user_id}/chgwithdrawkey`
            }
            axios.post(endpoint, {
              old_withdraw_key: this.editedItem.old_withdraw_key,
              withdraw_key: this.editedItem.withdraw_key
            })
              .then(function () {
                _this.editedItem = Object.assign({}, _this.defaultItem);
                _this.$store.dispatch('snackbar/openSnackbar', {
                  message: _this.$t('success'),
                  color: 'success',
                })
                _this.$refs.observer.reset()
              })
              .catch(function (error) {
                // Show error.
                _this.$store.dispatch('snackbar/openSnackbar', {
                  message: error.response.data.message,
                  color: 'error',
                })
              })
              .finally(function () {
                _this.busy = false;
              })
          }
        });
      },
    },
  }
</script>
