<template>
  <v-container fluid>
    <v-form @submit.prevent="generateReport">
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-card outlined>
            <v-card-text>
                  <v-combobox
                    v-model="selected_SORT_KEY"
                    :items="SORT_KEY"
                    label="SORT_KEY"
                    multiple
                    chips
                    clearable
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        <v-avatar
                          class="accent white--text"
                          left
                          v-text="selected_SORT_KEY.indexOf(data.item) + 1"
                        ></v-avatar>
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-card outlined>
            <v-card-text>
              <v-combobox
                v-model="selected_OUTPUT_KEY"
                :items="OUTPUT_KEY"
                label="OUTPUT_KEY"
                multiple
                chips
                clearable
              ></v-combobox>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
        >
          <v-card
            outlined
          >
            <v-card-title>Filter</v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fromDate"
                        label="Date From"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fromDate"
                      @input="menu1 = false"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="toDate"
                        label="Date To"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="toDate"
                      @input="menu2 = false"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    label="amount_min"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    label="amount_max"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-combobox
                    v-model="selected_bank_ids"
                    :items="bank_ids"
                    label="Bank IDs"
                    multiple
                    chips
                    clearable
                  ></v-combobox>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-combobox
                    v-model="selected_bank_card_ids"
                    :items="bank_card_ids"
                    label="Bank Card IDs"
                    multiple
                    chips
                    clearable
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" type="submit">Generate</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <template v-if="records">
      <v-divider></v-divider>
      <v-data-table
        :headers="tableHeaders"
        :items="records"
        :single-expand="true"
        item-key="name"
        show-expand
      >
<!--        <template v-slot:item="{ item, expand, isExpanded }">-->
<!--          <tr>-->
<!--            <td-->
<!--              class="d-block d-sm-table-cell"-->
<!--              v-for="(field, index) in Object.keys(item)"-->
<!--              v-bind:key="index"-->
<!--            >-->
<!--              {{item[field]}}-->
<!--            </td>-->
<!--            <td>-->
<!--              <v-btn icon @click="expand(!isExpanded)">-->
<!--                <v-icon-->
<!--                >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'-->
<!--                  }}</v-icon-->
<!--                >-->
<!--              </v-btn>-->
<!--            </td>-->
<!--          </tr>-->
<!--        </template>-->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            More info about {{ item.name }}
          </td>
<!--          <tr-->
<!--            v-for="(item,index) in records"-->
<!--            :key="index"-->
<!--            :colspan="headers.length"-->
<!--          >-->
<!--            <td v-for="(i,index) in Object.values(item)" v-bind:key="index">{{i + index}}</td>-->
<!--          </tr>-->
        </template>
<!--        <template v-slot:foot>-->
<!--          <tr>-->
<!--            <td>Totals</td>-->
<!--            <td>{{ data.total.usage.val }}</td>-->
<!--            <td>{{ data.total.amount.val }}</td>-->
<!--          </tr>-->
<!--        </template>-->
      </v-data-table>
    </template>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'BankCardReport',
    computed: {
      tableHeaders () {
        return [
          {
            text: 'Dessert (100g serving)',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Calories', value: 'calories' },
          { text: 'Fat (g)', value: 'fat' },
          { text: 'Carbs (g)', value: 'carbs' },
          { text: 'Protein (g)', value: 'protein' },
          { text: 'Iron (%)', value: 'iron' },
          { text: '', value: 'data-table-expand' },
        ];
      }
    },
    data: () => ({
      busy: false,
      fromDate: new Date(new Date().getFullYear(), 0, 1).toISOString().substr(0, 10),
      toDate: new Date().toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      selected_OUTPUT_KEY: [
        'transaction_month',    // transaction month
        'transaction_date',     // transaction date
        'bank_code',              // bank id
        'bank_card_id',         // bank card id
        'transaction_number',   // transaction number
      ],
      selected_SORT_KEY: [
        'amount',               // transaction amount
        'usage',                // usage count
      ],
      SORT_KEY: [
        'transaction_month',    // transaction month
        'transaction_date',     // transaction date
        'bank_code',              // bank id
        'bank_card_id',         // bank card id
        'transaction_number',   // transaction number
      ],
      OUTPUT_KEY: [
        'amount',               // transaction amount
        'usage',                // usage count
      ],
      selected_bank_ids: [],
      bank_ids: [1,2,3,4,5],
      selected_bank_card_ids: [],
      bank_card_ids: [1,2,3,4,5],
      records: [
        {
          name: 'Frozen Yogurt',
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: '1%',
        },
        {
          name: 'Ice cream sandwich',
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: '1%',
        },
        {
          name: 'Eclair',
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: '7%',
        },
      ],
      meta: null,
    }),
    methods: {
      async generateReport () {
        this.busy = true;
        const _this = this;
        await axios.post('/app/api/report/bankcard/transaction', {
          sort_key: _this.selected_OUTPUT_KEY,
          output_key: _this.selected_SORT_KEY,
          filter: {
            // bank_code: ['1', ],
            // bank_card_id: [131, ],

            // transaction_date_from: Date.parse(_this.fromDate),
            // transaction_date_to: Date.parse(_this.toDate),

            amount_min: 1, //cant be zero
            amount_max: 1000000,

            // The following only applicable if transaction_number is not choosen

            // usage_min: 0,
            // usage_max: 10000000,
          },
        })
          .then(function (response) {
            const {data} = response.data.data;
            const {meta} = response.data.data;
            _this.records = data;
            _this.metas = meta;
          }).catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          }).finally(function () {
            _this.busy = false;
          });
      },
    }
  }
</script>
